<template>
  <Modal
    title="签名密码"
    :value="visible"
    :mask-closable="false"
    :transfer="false"
    @on-cancel="onCancel"
    @on-ok="handleSubmit"
    :styles="{top: '100px'}"
  >
    <Form ref="form" :model="formData" :rules="formRules" v-if="visible">
      <FormItem prop="oldPassword" v-if="type === 'update'" label="登录密码">
        <Input
          placeholder="请输入登录密码"
          v-model="formData.oldPassword"
          type="password"
        />
      </FormItem>
      <FormItem prop="password" label="签名密码">
        <Input
          placeholder="请输入密码"
          v-model="formData.password"
          type="password"
        />
      </FormItem>
      <FormItem
        prop="confirmPassword"
        v-if="['add', 'update'].indexOf(type) > -1"
        label="确认密码"
      >
        <Input
          placeholder="请输入密码"
          v-model="formData.confirmPassword"
          type="password"
        />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button @click="onCancel">取消</Button>
      <Button type="primary" :loading="loading" @click="handleSubmit"
      >确定</Button
      >
    </div>
  </Modal>
</template>

<script>
import api from "@/api/personalInfo/userSign"
import { dataToSha } from "@/utils/util"

const { apiUpdatePassword } = api
export default {
  name: "Password",
  props: ["visible", "type"],
  data() {
    return {
      formData: {},
      loading: false,
      formRules: {
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码长度6-18位",
            trigger: "blur"
          },
          {
            ...this.rules.numberAndChar,
            message: "密码只能包含字母和数字",
            trigger: "blur"
          },
          {
            ...this.rules.password,
            message: "密码必须包含大写字母、小写字母和数字",
            trigger: "blur"
          }
        ],
        oldPassword: {
          required: true,
          message: "登录密码不能为空",
          trigger: "blur"
        },
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "密码长度6-18位",
            trigger: "blur"
          },
          {
            ...this.rules.numberAndChar,
            message: "密码只能包含字母和数字",
            trigger: "blur"
          },
          {
            ...this.rules.password,
            message: "密码必须包含大写字母、小写字母和数字",
            trigger: "blur"
          }
        ]
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = {}
        if (["use", "view"].indexOf(this.type) > -1) {
          this.formRules.password = [
            { required: true, message: "密码不能为空", trigger: "blur" }
          ]
        } else {
          this.formRules.password = [
            { required: true, message: "密码不能为空", trigger: "blur" },
            {
              min: 6,
              max: 18,
              message: "密码长度6-18位",
              trigger: "blur"
            },
            {
              ...this.rules.numberAndChar,
              message: "密码只能包含字母和数字",
              trigger: "blur"
            },
            {
              ...this.rules.password,
              message: "密码必须包含大写字母、小写字母和数字",
              trigger: "blur"
            }
          ]
        }
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit("onCancel")
    },
    // 提交
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { confirmPassword, password, oldPassword } = this.formData
          if (confirmPassword && confirmPassword !== password) {
            this.$Message.error("两次密码输入不一致！")
            return false
          }
          switch (this.type) {
            case "add":
              this.$asyncDo(async () => {
                this.loading = true
                const res = await apiUpdatePassword({
                  signPwd: dataToSha(password)
                })
                if (res) {
                  this.$Message.success("操作成功！")
                  this.$emit("onOk")
                }
                this.loading = false
              })
              break
            case "update":
              this.$asyncDo(async () => {
                this.loading = true
                const res = await apiUpdatePassword({
                  signPwd: dataToSha(password),
                  loginPwd: dataToSha(oldPassword)
                })
                if (res) {
                  this.$Message.success("操作成功！")
                  this.$emit("onOk")
                }
                this.loading = false
              })
              break
            case "use":
              this.$emit("onSubmit", dataToSha(password))
              break
            case "view":
              this.$emit("onView", dataToSha(password))
              break
            default:
              this.$emit("onSubmit", dataToSha(password))
              break
          }
        }
        return false
      })
    }
  }
}
</script>
