<template>
	<div class="content-wrap">
		<div class="sign-wrap">
			<div class="sign-info">
				<div class="info-item">
					<h2>签名密码：</h2>
					<p>
						<Button v-if="signCode === 0" @click="passowordShow('add')"
							>设置</Button
						>
						<Button v-else @click="passowordShow('update')">修改</Button>
					</p>
				</div>
				<div class="info-item">
					<h2>个人签名：</h2>
					<div class="img">
						<span class="warning" v-if="signCode === 0">请先设置签名密码</span>
						<span class="warning" v-if="signCode === 1">未上传签名</span>
						<p v-if="signCode === 2">
							<img :src="signUrl" />
						</p>
					</div>
				</div>
			</div>
			<div class="sign-design">
				<Upload
					action="/"
					accept="image/png, image/jpeg, image/jpg"
					:before-upload="beforeUpload"
				>
					<Button :disabled="signCode === 0" :loading="loading"
						>选择图片</Button
					>
				</Upload>
				<div class="upload-info">
					<p>重点提示： 请优先使用高拍仪，保障图片质量，背景以纯白色为佳。</p>
					<p>只支持上传png、jpg格式。</p>
				</div>
				<div class="sign-content" :style="{ height: dataGridHeight + 'px' }">
					<vueCropper
						ref="cropper"
						:autoCropWidth="200"
						:autoCropHeight="80"
						:fixedBox="true"
						:img="img"
						:autoCrop="true"
						:high="false"
						outputType="png"
						mode="contain"
					></vueCropper>
				</div>
				<div class="action-button">
					<Button :disabled="isDisable" @click="changeScale(2)">
						<Icon size="24" type="ios-add" />
					</Button>
					<Button :disabled="isDisable" @click="changeScale(-2)">
						<Icon size="24" type="ios-remove" />
					</Button>
					<Button :disabled="isDisable" @click="rorateLeft">
						<Icon size="24" type="md-undo" />90°
					</Button>
					<Button :disabled="isDisable" @click="rorateRight">
						<Icon size="24" type="md-redo" />90°
					</Button>
				</div>
				<div class="sign-footer">
					<Button
						:disabled="isDisable"
						type="primary"
						:loading="loading"
						@click="passowordShow('use')"
						>确定</Button
					>
				</div>
			</div>
		</div>
		<Password
			v-if="passwordVisible"
			:visible="passwordVisible"
			@onCancel="onCancel"
			@onOk="handleOk"
			@onSubmit="handleSubmit"
			@onView="handleView"
			:type="passwordType"
		></Password>
	</div>
</template>

<script>
import { VueCropper } from "vue-cropper"
import api from "@/api/personalInfo/userSign"
import { mapState } from "vuex"
import Password from "./Password.vue"

const { apiSetSignature, apiIsHasSignPassword, apiGetSignatureByPassword } = api
export default {
	name: "Sign",
	props: ["id", "signHeight"],
	data() {
		return {
			img: "",
			signUrl: "",
			passwordVisible: false,
			loading: false,
			file: null,
			signCode: 0,
			passwordType: "add",
			getSignatureLoading: false
		}
	},
	components: {
		VueCropper,
		Password
	},
	computed: {
		...mapState({
			conHeight: state => state.contentHeight - 190
		}),
		dataGridHeight() {
			return this.signHeight ? this.signHeight : this.conHeight
		},
		isDisable() {
			return !this.img || this.img === "/noUrl.png"
		}
	},
	watch: {
		signCode() {
			if (this.signCode === 2 && this.$route.name === "personalSignature") {
				this.handleView()
			}
		}
	},
	mounted() {
		this.isHasSign()
	},
	methods: {
		onCancel() {
			this.passwordVisible = false
		},
		// 向右旋转
		rorateRight() {
			this.$refs.cropper.rotateRight()
		},
		// 向左旋转
		rorateLeft() {
			this.$refs.cropper.rotateLeft()
		},
		// 放大，缩小
		changeScale(num) {
			this.$refs.cropper.changeScale(num)
		},
		// 校验是否有签名密码，和签名
		isHasSign() {
			this.$asyncDo(async () => {
				const res = await apiIsHasSignPassword()
				if (res) {
					this.signCode = window.parseInt(res.data)
					// if (this.signCode === 2) {
					//   this.handleView();
					// }
				}
			})
		},
		// 提交
		handleSubmit(password) {
			if (!password) {
				this.$Message.error("请输入签名密码！")
				return false
			}
			this.$refs.cropper.getCropData(data => {
				this.passwordVisible = false
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiSetSignature({
						signPwd: password,
						file: data,
						filename: this.file.name
					})
					if (res) {
						this.$Message.success("操作成功!")
						this.signUrl = res.data
						// 清空时，需要设置一个找不到地址的图片
						this.img = "/noUrl.png"
						this.file = null
						this.$nextTick(() => {
							this.$refs.cropper.refresh()
						})
						this.isHasSign()
					}
					this.loading = false
				})
			})
			return true
		},
		// 图片处理成base64
		beforeUpload(file) {
			// if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
			//   this.$Message.error('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种');
			//   return false;
			// }
			if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(file.name)) {
				this.$Message.error("图片类型必须是.jpg,png中的一种")
				return false
			}
			let data
			if (typeof file === "object") {
				// 把Array Buffer转化为blob 如果是base64不需要
				data = window.URL.createObjectURL(new Blob([file]))
				this.file = file
			} else {
				data = file
			}
			this.img = data
			return false
		},
		// 密码显示
		passowordShow(type) {
			this.passwordVisible = true
      console.log(this.passwordVisible)
			this.passwordType = type
		},
		// 修改或者重置密码成功
		handleOk() {
			this.passwordVisible = false
			this.isHasSign()
		},
		// 查看签名密码
		handleView() {
			this.$asyncDo(async () => {
				this.getSignatureLoading = true
				const res = await apiGetSignatureByPassword({})
				if (res) {
					this.signUrl = res.data
				}
				this.getSignatureLoading = false
			})
			return true
		}
	}
}
</script>

<style lang="less">
.sign-wrap {
	display: flex;
	.sign-info {
		flex-basis: 216px;
		width: 216px;
		.info-item {
			margin-bottom: 15px;
			h2 {
				margin-bottom: 10px;
			}
			.img {
				width: 200px;
				height: 80px;
				.warning {
					color: #ff4e00;
				}
				img {
					width: 100%;
					height: 100%;
					border: 1px solid #ccc;
				}
			}
		}
	}
	.sign-design {
		flex: 1;
		padding-left: 16px;
		border-left: 1px solid #ccc;
		.sign-content {
			height: 500px;
		}
		.upload-info {
			line-height: 30px;
			color: #ff4e00;
		}
		.action-button {
			padding: 10px 0;
			text-align: center;
			.ivu-btn {
				margin: 0 10px;
			}
		}
		.sign-footer {
			text-align: right;
			.ivu-btn {
				margin-right: 10px;
			}
		}
	}
}
</style>
