import request from "@/utils/request"

// 定义 API 路径常量
const API_PATH = {
	SIGNATURE_VERIFY_EXISTS: "/auth/self-info/signature-verify-exists",
	SET_SIGNATURE: "/auth/self-info/signature",
	GET_SIGNATURE_BY_PASSWORD: "/auth/self-info/signature",
	UPDATE_PASSWORD: "/auth/self-info/signature-reset"
}

// 统一请求封装函数
const makeRequest = (url, options) =>
	request(url, options)
		.then(response => response)
		.catch(error => {
			console.error("请求失败:", error)
			throw error
		})

export default {
	// 验证是否已设置签名密码
	apiIsHasSignPassword: () =>
		makeRequest(API_PATH.SIGNATURE_VERIFY_EXISTS, { method: "post" }),

	// 上传签名文件
	apiSetSignature: data => {
		if (!data || !data.file) {
			throw new Error("缺少必要的参数")
		}
		return makeRequest(API_PATH.SET_SIGNATURE, {
			method: "post",
			body: data
		})
	},

	// 获取签名图片
	apiGetSignatureByPassword: data => {
		if (!data || !data.password) {
			throw new Error("缺少必要的参数")
		}
		return makeRequest(API_PATH.GET_SIGNATURE_BY_PASSWORD, {
			method: "get",
			params: data
		})
	},

	// 新增、修改签名密码
	apiUpdatePassword: data => {
		if (!data || !data.newPassword) {
			throw new Error("缺少必要的参数")
		}
		return makeRequest(API_PATH.UPDATE_PASSWORD, {
			method: "put",
			body: data
		})
	}
}
